<template>
	<KeyValueCard2
		:item="detail"
		:cols="filteredRows"
		:title="'Session detail'"
		:loading="false"
		color="secondary">
		<template #[`item.start_time.value`]="{ item }">
			{{ item.start_time | Date }}
		</template>
		<template #[`item.end_time.value`]="{ item }">
			{{ item.start_time | Date }}
		</template>
		<template #[`item.sum_bet.value`]="{ item }">
			{{ item.sum_bet | Number({ currency }) }}
		</template>
		<template #[`item.sum_win.value`]="{ item }">
			{{ item.sum_bet | Number({ currency }) }}
		</template>
		<template #[`item.netto.value`]="{ item }">
			{{ item.sum_bet | Number({ currency }) }}
		</template>
	</KeyValueCard2>
</template>
<script>
import KeyValueCard2 from "../../components/shared/keyValueCard2.vue";
import { ROUND_FLAGS } from "../../constants/constants";
export default {
	components: {
		KeyValueCard2,
	},
	props: {
		item: Object,
		color: { type: String, default: "primary" },
		device: Number,
	},
	computed: {
		filteredRows() {
			return this.rows.map((row) =>
				row.filter((item) => !item.hide || !item.hide())
			);
		},
	},
	data() {
		return {
			ROUND_FLAGS,
			detail: {
				...this.item,
			},
			currency: this.item.currency,
			rows: [
				[
					{ label: "Player", key: "player_mongo_id" },
					{ label: "Session ID", key: "session_mongo_id" },
				],
				[
					{ label: "Game Name", key: "game_name" },
					{ label: "Game ID", key: "game_id" },
				],
				[
					{ label: "Start Time", key: "start_time" },
					{ label: "End Time", key: "end_time" },
				],
				[
					{
						label: "Internal ID",
						key: "internal",
						hide: () => !this.detail.internal,
					},
					{
						label: "External ID",
						key: "external",
						hide: () => !this.detail.external,
					},
					{
						label: "External Ticket ID",
						key: "external_ticket_id",
						hide: () => !this.detail.external_ticket_id,
					},
				],
				[
					{ label: "Sum Bet", key: "sum_bet" },
					{ label: "Sum Win", key: "sum_win" },
				],
				[
					{ label: "Netto", key: "netto" },
					{ label: "Rounds", key: "rounds_count" },
				],
				[{ label: "Currency", key: "currency" }],
			],
		};
	},
};
</script>
