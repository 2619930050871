var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("KeyValueCard2", {
    attrs: {
      item: _vm.detail,
      cols: _vm.filteredRows,
      title: "Session detail",
      loading: false,
      color: "secondary",
    },
    scopedSlots: _vm._u(
      [
        {
          key: `item.start_time.value`,
          fn: function ({ item }) {
            return [_vm._v(" " + _vm._s(_vm._f("Date")(item.start_time)) + " ")]
          },
        },
        {
          key: `item.end_time.value`,
          fn: function ({ item }) {
            return [_vm._v(" " + _vm._s(_vm._f("Date")(item.start_time)) + " ")]
          },
        },
        {
          key: `item.sum_bet.value`,
          fn: function ({ item }) {
            return [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("Number")(item.sum_bet, { currency: _vm.currency })
                  ) +
                  " "
              ),
            ]
          },
        },
        {
          key: `item.sum_win.value`,
          fn: function ({ item }) {
            return [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("Number")(item.sum_bet, { currency: _vm.currency })
                  ) +
                  " "
              ),
            ]
          },
        },
        {
          key: `item.netto.value`,
          fn: function ({ item }) {
            return [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("Number")(item.sum_bet, { currency: _vm.currency })
                  ) +
                  " "
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }